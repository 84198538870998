<template>
  <v-form>
    <v-row>
      <v-col cols="4">
        <v-text-field
          outlined
          dense
          label="Code"
          v-model="record.Code"
        ></v-text-field>
      </v-col>

      <v-col cols="4">
        <v-text-field
          outlined
          dense
          label="Name"
          v-model="record.Name"
        ></v-text-field>
      </v-col>
      <v-col cols="4">
        <v-autocomplete
          item-text="text"
          outlined
          dense
          item-value="value"
          v-model="record.LogType"
          label="Check in Type"
          :items="LogTypes"
        ></v-autocomplete>
      </v-col>
      <v-col cols="4">
        <v-menu
          ref="menu"
          v-model="menu1"
          :close-on-content-click="false"
          :nudge-right="40"
          :return-value.sync="time1"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <div>
              <v-text-field
                v-model="record.CheckInTime"
                label="CheckIn Time"
                prepend-inner-icon="mdi-clock-time-four-outline"
                readonly
                outlined
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </div>
          </template>
          <v-time-picker
            v-if="menu1"
            v-model="record.CheckInTime"
            full-width
            @click:minute="$refs.menu.save(time1)"
          ></v-time-picker>
        </v-menu>
      </v-col>
      <v-col cols="4">
        <v-menu
          ref="menu"
          v-model="menu2"
          :close-on-content-click="false"
          :nudge-right="40"
          :return-value.sync="time2"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              outlined
              v-model="record.CheckOutTime"
              label="CheckOut Time"
              prepend-inner-icon="mdi-clock-time-four-outline"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-time-picker
            v-if="menu2"
            v-model="record.CheckOutTime"
            full-width
            @click:minute="$refs.menu.save(time2)"
          ></v-time-picker>
        </v-menu>
      </v-col>

      <v-col cols="4">
        <v-checkbox label="Active" v-model="record.Active"></v-checkbox>
      </v-col>

      <v-col cols="12">
        <v-btn color="accent" @click="sendData" :loading="loader">
          <v-icon left>mdi-content-save</v-icon>Save
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>


<script>
export default {
  props: {
    initial: {
      type: Object,
    },
  },
  data: () => ({
    record: {},
    LogTypes: [
      { text: "Auto", value: 1 },
      { text: "Manual", value: 1 },
    ],
    menu2: false,
    menu1: false,
    time1: "",
    time2: "",
    loader: false,
  }),
  watch: {
    initial: {
      handler: "init",
      immediate: true,
    },
  },
  methods: {
    init(val) {
      this.record = { ...val };
    },
    setDate(date) {
      this.record.date = date;
    },
    sendData() {
      this.$emit("data", this.record);
    },
  },
};
</script>